<template>
  <section id="all-chats" class="all-chats scroll">
    <div class="all-chats__search">
      <vs-input
        id="search-box"
        icon="icon-search"
        icon-pack="feather"
        class="input-rounded-full no-icon-border w-full"
        :placeholder="
          lang.attentionRequests.searchPlaceholder[languageSelected]
        "
        v-model="search"
        @input="debounceSearch"
      />
    </div>

    <div class="all-chats__chats">
      <div class="text-primary title" slot="header">
        <span>
          {{ lang.attentionRequests.sidenav.chats[languageSelected] }}
          <span v-if="totalCount"> ({{ totalCount }})</span>
        </span>
        <vs-button
          :disabled="exportingData"
          size="small"
          icon-pack="feather"
          :icon="exportingData ? '' : 'icon-download'"
          icon-after
          type="flat"
          @click="exportData"
        >
          {{
            exportingData
              ? lang.attentionRequests.sidenav.buttons.exporting[
                  languageSelected
                ]
              : lang.attentionRequests.sidenav.buttons.export[languageSelected]
          }}
        </vs-button>
      </div>

      <!-- <div v-if="chats.length && search" class="ml-6">
        {{ chats.length }}
        {{ lang.attentionRequests.resultsFoundFor[languageSelected] }}
        <strong>"{{ search }}"</strong>
      </div> -->

      <div class="chat__list">
        <InfiniteScroll :items="chats" item-key="_id" @refetch="showMore">
          <template slot="item" slot-scope="{ item }">
            <div
              @click="updateActiveChatUser(item)"
              class="cursor-pointer hovered"
            >
              <ChatContact :contact="item" :highlight="search" />
            </div>
          </template>
        </InfiniteScroll>

        <div v-if="!chats.length && !loading" class="list-message">
          <i class="material-icons"> search </i>
          <span class="ml-2">
            {{ lang.general.noDataText[languageSelected] }}
          </span>
        </div>

        <div v-if="chats.length && isLastPage" class="list-message">
          <i class="material-icons"> check_circle_outline </i>
          <span class="ml-2">
            {{ lang.attentionRequests.allChatsShown[languageSelected] }}
          </span>
        </div>

        <div class="show-more-button">
          <div
            class="vs-con-loading__container loading-more"
            :class="{ activeLoading: loading }"
            :id="['loading-border']"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { USER_ROLES } from '@/models/Roles/UserRoles'
import { CHAT_EXPORT_HEADERS } from '@/constants/constants'
import konecta from '@/services/konecta'
import debounce from 'lodash.debounce'
import { download } from '@/utils/index'

import staffManagementMixin from '@/mixins/staffManagementMixin'

const DEBOUNCE_TIME = 300

export default {
  name: 'ChatList',
  components: {
    ChatContact: () =>
      import(
        '../../../attention-requests/chat/sidenav/chat-list/ChatContact.vue'
      ),
    InfiniteScroll: () => import('@/components/InfiniteScroll.vue')
  },
  props: {
    filters: Object
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      chats: [],
      totalCount: null,
      skip: 0,
      limit: 20,
      search: '',
      showingMore: false,
      loading: false,
      exportingData: false
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.resetSearch()
      }
    },
    joinUpdate() {
      if (
        this.activeChatUser &&
        this.activeChatUser.sender &&
        this.activeChatUser.service
      ) {
        const session = localStorage.getItem('session')
        const token = JSON.parse(session).token
        this.$socket.client.emit('join', {
          type: 'sender',
          token,
          service: this.activeChatUser.service,
          sender: this.activeChatUser.sender
        })
      }
    },
    newChatUserName() {
      const chatIndex = this.chats.findIndex(
        c => c._id === this.newChatUserName._id
      )
      this.chats[chatIndex].fullname = this.newChatUserName.fullname
    },
    bot() {
      console.warn('All Chats - bot watcher called')
      // this.chats = []
      // this.limit = this.defaultNumChats
      // this.shown = this.defaultNumChats
      // this.skip = 0
      // this.getChats(false, true)
      // this.getChats(false, false)
      // this.getBotSocket()
      // this.watchMessageStatus2()
    }
  },
  computed: {
    ...mapState([
      'lang',
      'languageSelected',
      'activeChatUser',
      'bot',
      'session',
      'joinUpdate',
      'newChatUserName',
      'session',
      'useStaffManagement'
    ]),
    showMoreCount() {
      return this.totalCount - this.chats.length
    },
    isLastPage() {
      return this.totalCount === this.chats.length
    }
  },
  methods: {
    ...mapActions(['updateContains']),
    ...mapActions('contact', ['getChats', 'exportAll']),
    ...mapMutations([
      'UPDATE_ACTIVE_CHAT',
      'UPDATE_ACTIVE_MESSAGES',
      'SELECT_BOT'
    ]),
    updateActiveChatUser(contact) {
      const index = this.chats.findIndex(c => c._id === contact._id)

      let chatUpdate = this.chats[index]
      chatUpdate.unseenMsg = 0
      this.$set(this.chats, index, chatUpdate)

      let chat = contact
      chat.index = index
      chat.unseenMsg = 0

      const session = localStorage.getItem('session')
      const token = JSON.parse(session).token

      if (
        this.activeChatUser &&
        this.activeChatUser.sender &&
        (this.activeChatUser.sender !== chat.sender ||
          this.activeChatUser.service !== chat.service)
      ) {
        this.$socket.client.emit('leave', {
          type: 'sender',
          token,
          service: this.activeChatUser.service,
          sender: this.activeChatUser.sender
        })
      }
      this.$socket.client.emit('join', {
        type: 'sender',
        token,
        service: chat.service,
        sender: chat.sender
      })

      const botIndex = this.bots.findIndex(b => b._id === chat.bot)
      this.SELECT_BOT(this.bots[botIndex])
      this.UPDATE_ACTIVE_CHAT(chat)
      this.$emit('updateActiveChatUser')
    },
    async showMore() {
      if (this.loading) return

      this.skip += this.limit
      await this.getPeople(false)
    },
    getQueryParams(paginate = true) {
      let filter = paginate ? `skip=${this.skip}&limit=${this.limit}` : ''
      this.filters.status.forEach(s => (filter += `&where[status][$in]=${s}`))

      filter += `&where[_updatedAt][$gte]=${new Date(
        this.filters.date.from
      ).toISOString()}`
      filter += `&where[_updatedAt][$lte]=${new Date(
        this.filters.date.to
      ).toISOString()}`
      this.filters.platforms.forEach(
        platform => (filter += `&where[platform][$in]=${platform}`)
      )
      this.filters.versions.forEach(
        version => (filter += `&where[version][$in]=${version}`)
      )
      if (this.filters.text && this.filters.text.length > 0) {
        filter += `&where[contains]=${this.filters.text}`
      }
      if (this.search.length) {
        const anonymousUser = this.lang.attentionRequests.sidenav.anonymousUser[
          this.languageSelected
        ].toLowerCase()

        filter += anonymousUser.includes(this.search.toLowerCase())
          ? `&where[$or][0][fullname][$regex]=${this.search}&where[$or][0][fullname][$options]=i&where[$or][1][fullname][$exists]=false`
          : `&where[fullname][$regex]=${this.search}&where[fullname][$options]=i`
      }

      filter += this.filters.staging ? '&where[staging]=true' : ''

      return filter
    },
    getPeople(clear = true) {
      if (!this.filters.versions.length || (this.isLastPage && this.skip !== 0))
        return
      if (clear) {
        this.UPDATE_ACTIVE_CHAT()
        if (
          this.activeChatUser &&
          this.activeChatUser.service &&
          this.activeChatUser.sender
        ) {
          const session = localStorage.getItem('session')
          const token = JSON.parse(session).token
          this.$socket.client.emit('leave', {
            type: 'sender',
            token,
            service: this.activeChatUser.service,
            sender: this.activeChatUser.sender
          })
        }

        this.$vs.loading({ container: '#chat-app' })
      }

      this.loading = true
      this.$vs.loading({
        container: '#loading-border',
        type: 'border'
      })
      const queryParams = this.getQueryParams()

      this.getChats(queryParams)
        .then(response => {
          const anonymousUser = this.lang.attentionRequests.sidenav
            .anonymousUser[this.languageSelected]
          const chats = response.data.result
          chats.forEach(c => (c.fullname = c.fullname || anonymousUser))

          this.chats = clear ? chats : this.chats.concat(chats)
          // this.totalCount = !this.search
          //   ? response.data.length
          //   : this.totalCount
          this.totalCount = response.data.length
          this.updateContains(this.filters.text) // highlights text in chats
          if (clear) this.$vs.loading.close('#chat-app > .con-vs-loading')

          this.loading = false
          this.$vs.loading.close('#loading-border > .con-vs-loading')
        })
        .catch(error => {
          if (error.message !== 'cancelled') {
            if (clear) this.$vs.loading.close('#chat-app > .con-vs-loading')
            this.loading = false
            this.$vs.loading.close('#loading-border > .con-vs-loading')
            this.$vs.notify({
              title: 'Error',
              text: this.lang.attentionRequests.errorGetPeople[
                this.languageSelected
              ],
              color: 'danger'
            })
          }
        })
    },
    resetSearch() {
      this.skip = 0
      // this.totalCount = null
      this.getPeople()
    },
    debounceSearch: debounce(function() {
      this.resetSearch()
    }, DEBOUNCE_TIME),
    onlyAgent() {
      return (
        this.session.user.roles.ids.indexOf(USER_ROLES.AGENT) > -1 &&
        this.session.user.roles.ids.indexOf(USER_ROLES.SUPERVISOR) === -1 &&
        this.session.user.roles.ids.indexOf(USER_ROLES.SUPER_ADMIN) === -1
      )
    },
    async getBots() {
      let query = this.onlyAgent() ? `?as[role]=${USER_ROLES.AGENT}` : ''
      if (
        this.useStaffManagement
        && !this.isAuthorized({ key: 'konecta.bot_maker.edit_bot_settings' })
      ) {
        query = `?as[role]=${USER_ROLES.AGENT}`
      }

      const response = await konecta.get('/bot' + query)
      this.bots = response.data.map(element => {
        return {
          _id: element._id,
          name: element.service.name,
          service: element.service,
          company: element.company,
          platform: 'any',
          showEmotions: element.showEmotions
        }
      })
    },
    message(data) {
      this.chats.forEach((chat, index) => {
        if (data.bot === chat.bot && data.sender === chat.sender) {
          let chatUpdate = this.chats[index]
          chatUpdate._lastMessageAt = data.message._createdAt
          if (
            this.activeChatUser &&
            this.activeChatUser.sender !== chat.sender
          ) {
            // si el chat es otro que no esta abierto
            if (!isNaN(this.chats[index].unseenMsg)) {
              this.chats[index].unseenMsg++
            } else {
              chatUpdate.unseenMsg = 1
            }
          } else if (!this.activeChatUser) {
            if (!isNaN(this.chats[index].unseenMsg)) {
              this.chats[index].unseenMsg++
            } else {
              chatUpdate.unseenMsg = 1
            }
          }
          this.$set(this.chats, index, chatUpdate)
        }
      })
    },
    parseExportData(data) {
      const dataArray = data.split('\n')
      const newArrayData = dataArray.map((row, index) => {
        let rowArray = row.split(',')
        if (index !== 0) {
          // replace status id
          const maxStatus = this.lang.attentionRequests.chatStatus.length - 1
          const currentStatus = parseInt(rowArray[5])

          if (currentStatus >= 0 && currentStatus <= maxStatus) {
            rowArray[5] = `${
              this.lang.attentionRequests.chatStatus[currentStatus][
                this.languageSelected
              ]
            }`
          } else {
            rowArray[5] = ''
          }
        } else {
          // replace headers
          for (let i = 0; i < rowArray.length; i++) {
            rowArray[i] = `"${
              CHAT_EXPORT_HEADERS[JSON.parse(rowArray[i])][
                this.languageSelected
              ]
            }"`
          }
        }
        return rowArray.join(',')
      })

      return newArrayData.join('\n')
    },
    exportData() {
      const queryParams = this.getQueryParams(false)

      this.exportingData = true
      this.$vs.notify({
        title: this.lang.attentionRequests.sidenav.buttons.export[
          this.languageSelected
        ],
        text: this.lang.attentionRequests.sidenav.buttons.exportText[
          this.languageSelected
        ],
        color: 'warning'
      })
      this.exportAll(queryParams)
        .then(response => {
          const parsedData = this.parseExportData(response.data)
          const filename = `konecta_export_${new Date().toISOString()}`
          download(parsedData, `${filename}.csv`)
          this.exportingData = false
        })
        .catch(() => {
          this.exportingData = false
          this.$vs.notify({
            title: 'Error',
            text: this.lang.attentionRequests.errorExport[
              this.languageSelected
            ],
            color: 'danger'
          })
        })
    }
  },
  async mounted() {
    await this.getBots()
    await this.getPeople()
    this.$socket.client.on('message', this.message)
  },
  beforeDestroy() {
    this.$socket.client.off('message')
  }
}
</script>

<style lang="scss">
.all-chats {
  &__search {
    padding: 20px;
  }

  &__chats {
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      padding: 0 20px 5px 20px;
    }
    .hovered:hover {
      background: #f4f4f4;
    }
    .show-more-button {
      display: flex;
      justify-content: center;
      color: unset;
      padding-top: 5px;
      // border-top: 1px solid #dae1e7;
    }
    .chat__list {
      .list-message {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;
        color: #adadad;
        font-size: 16px;
      }
      i {
        font-size: 16px;
      }
    }
  }

  .loading-more {
    width: 80px;
    height: 80px;
    transition: all 0.3s ease;
  }
}
</style>
