<template>
  <section class="all-chats">
    <Filters
      class="mb-6 chat-filter"
      :filter-model="filterModel"
      @init="filterChange"
      @on-apply="onApplyFilters"
    />
    <Chat :filters="filters" />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Chat from './chat/Chat'
import { ALL_CHATS_PLATFORMS } from '@/constants/constants'

export default {
  data() {
    return {
      filterModel: null,
      filters: {
        bots: [],
        date: {},
        versions: [],
        platforms: [],
        status: [],
        text: '',
        staging: false
      },
      statusFilterOptions: []
    }
  },
  components: {
    Chat,
    Filters: () => import('../metrics/filters/Filters.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  watch: {
    languageSelected() {
      this.initFilters()
    }
  },
  methods: {
    parseRange(values) {
      const from = values[0]
      const to = values[1]

      return { from, to }
    },
    onApplyFilters(filters) {
      const filtersTypes = Object.keys(filters)
      filtersTypes.forEach(filter => this.filterChange(filters[filter]))
    },
    filterChange(filter) {
      switch (filter.type) {
        case 'bot':
          this.filters.versions = filter.value.map(b => b._id)
          break
        case 'date':
          if (filter.value[0] && filter.value[1]) {
            this.filters.date = this.parseRange(filter.value)
          }
          break
        case 'platform':
          this.filters.platforms = filter.value //.map(platform => platform)
          break
        case 'status':
          this.filters.status = filter.value
          break
        case 'text':
          this.filters.text = filter.value
          break
        case 'staging':
          this.filters.staging = filter.value
          break
        default:
          break
      }
    },
    getStatusFilterOptions() {
      this.statusFilterOptions = [
        {
          name: this.lang.attentionRequests.sidenav.status.options.initial[
            this.languageSelected
          ],
          code: 0
        },
        {
          name: this.lang.attentionRequests.sidenav.status.options.pending[
            this.languageSelected
          ],
          code: 1
        },
        {
          name: this.lang.attentionRequests.sidenav.status.options.attended[
            this.languageSelected
          ],
          code: 2
        },
        {
          name: this.lang.attentionRequests.sidenav.status.options.released[
            this.languageSelected
          ],
          code: 3
        }
      ]
    },
    initFilters() {
      this.filters.date = this.monthInterval()
      this.getStatusFilterOptions()
      this.filterModel = [
        {
          name: 'bots',
          component: 'BotFilter',
          width: 4,
          props: {
            allowEmpty: false,
            startAll: true
          }
        },
        {
          name: 'dates',
          component: 'DateRangeFilter',
          width: 4,
          props: {
            value: this.filters.date
          }
        },
        {
          name: 'platform',
          component: 'PlatformFilter',
          width: 4,
          props: {
            multipleSelect: true,
            options: ALL_CHATS_PLATFORMS,
            key: this.filterKey,
            startAll: true
          }
        },
        {
          name: 'status',
          component: 'StatusFilter',
          width: 4,
          props: {
            options: this.statusFilterOptions
          }
        },
        {
          name: 'text',
          component: 'TextFilter',
          width: 4
        },
        {
          name: 'staging',
          component: 'StagingFilter',
          width: 4
        }
      ]
    },
    // weekInterval() {
    //   const to = new Date()
    //   let from = new Date(to.getTime())
    //   from.setDate(from.getDate() - 7)
    //   return {
    //     from: new Date(from.setHours(0, 0, 0, 0)),
    //     to: new Date(to.setHours(23, 59, 59, 999))
    //   }
    // },
    monthInterval() {
      const to = new Date()
      let from = new Date(to.getTime())
      from.setDate(from.getDate() - 30)
      return {
        from: new Date(from.setHours(0, 0, 0, 0)),
        to: new Date(to.setHours(23, 59, 59, 999))
      }
    }
  },
  mounted() {
    this.initFilters()
  }
}
</script>

<style lang="scss">
.chat-filter {
  z-index: 3100;
  position: relative;
}
</style>
