<template>
  <vs-popup
    classContent="popup-example simple-popup"
    :title="lang.newChat.popup.title[languageSelected]"
    :active.sync="show"
  >
    <NewChatWizard
      ref="newChatWizard"
      @onFinish="onFinish"
      :title="lang.newChat.wizard.title[languageSelected]"
    />
  </vs-popup>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    popup: Boolean
  },
  components: {
    NewChatWizard: () => import('./NewChatWizard.vue')
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    popup(newVal) {
      this.show = newVal
      if (this.show) {
        this.$refs.newChatWizard.reset()
      }
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    onFinish() {
      this.show = false
    }
  }
}
</script>