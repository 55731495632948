import Request from './request'
import state from '../store/state'

const PATH = {
  CHAT: '/chat'
}

/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _createChat = body => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(`${state.config.konecta}${PATH.CHAT}`, body, config)
}

const _updateChat = (chatId, body) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    `${state.config.konecta}${PATH.CHAT}/${chatId}`,
    body,
    config
  )
}

export default {
  createChat: _createChat,
  updateChat: _updateChat
}
